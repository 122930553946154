import { useStaticQuery, graphql } from 'gatsby'

const useTop10RealEstateWeb2019MetaData = () => {
  const query = useStaticQuery(graphql`
    query Top10RealEstateWeb2019MetaData {
      BannerImage1: file(
        relativePath: {
          eq: "top-10-real-estate-websites/2019/banner/image-1.png"
        }
      ) {
        id
        ...Top10RealEstateWeb2019Optimize
      }
      BannerBG: file(
        relativePath: {
          eq: "top-10-real-estate-websites/2019/banner/banner-bg.jpg"
        }
      ) {
        id
        ...Top10RealEstateWeb2019Optimize
      }
      Item1: file(
        relativePath: {
          eq: "top-10-real-estate-websites/2019/websites/item-1.png"
        }
      ) {
        id
        ...Top10RealEstateWeb2019Optimize
      }
      Item2: file(
        relativePath: {
          eq: "top-10-real-estate-websites/2019/websites/item-2.png"
        }
      ) {
        id
        ...Top10RealEstateWeb2019Optimize
      }
      Item3: file(
        relativePath: {
          eq: "top-10-real-estate-websites/2019/websites/item-3.png"
        }
      ) {
        id
        ...Top10RealEstateWeb2019Optimize
      }
      Item4: file(
        relativePath: {
          eq: "top-10-real-estate-websites/2019/websites/item-4.png"
        }
      ) {
        id
        ...Top10RealEstateWeb2019Optimize
      }
      Item5: file(
        relativePath: {
          eq: "top-10-real-estate-websites/2019/websites/item-5.png"
        }
      ) {
        id
        ...Top10RealEstateWeb2019Optimize
      }
      Item6: file(
        relativePath: {
          eq: "top-10-real-estate-websites/2019/websites/item-6.png"
        }
      ) {
        id
        ...Top10RealEstateWeb2019Optimize
      }
      Item7: file(
        relativePath: {
          eq: "top-10-real-estate-websites/2019/websites/item-7.png"
        }
      ) {
        id
        ...Top10RealEstateWeb2019Optimize
      }
      Item8: file(
        relativePath: {
          eq: "top-10-real-estate-websites/2019/websites/item-8.png"
        }
      ) {
        id
        ...Top10RealEstateWeb2019Optimize
      }
      Item9: file(
        relativePath: {
          eq: "top-10-real-estate-websites/2019/websites/item-9.png"
        }
      ) {
        id
        ...Top10RealEstateWeb2019Optimize
      }
      Item10: file(
        relativePath: {
          eq: "top-10-real-estate-websites/2019/websites/item-10.png"
        }
      ) {
        id
        ...Top10RealEstateWeb2019Optimize
      }
    }

    fragment Top10RealEstateWeb2019Optimize on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }
  `)

  return query
}

export default useTop10RealEstateWeb2019MetaData
