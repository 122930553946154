export const pageData = {
  title: '2019 Top 10 Real Estate Websites',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: '2019 Top 10 Real Estate Websites',
      url: '/top-10-real-estate-websites/2019',
    },
  ],
  uri: '/top-10-real-estate-websites/2019',
}

export const websiteTexts = [
  {
    title: 'The Oppenheim Group',
    subtitle: 'West Hollywood, CA',
    description:
      '“Selling Sunset” stars The Oppenheim Group represents some of Los Angeles’ most distinctive properties. At OGroup.com, Agent Image captures this perfectly through a sleek black and white palette with red accents throughout. The entire team is put front and center, looking every bit as polished as the clients they represent, because The Oppenheim Group is very much about people as it is about LA real estate.',
  },
  {
    title: 'The Altman \nBrothers',
    subtitle: 'West Hollywood, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
  {
    title: 'James \nWeekley',
    subtitle: 'Beverly Hills, CA',
    description:
      'Representing Southern California’s most exclusive properties, James Weekley brings you the “Douglas Elliman Trifecta”, which lets top-tier clients take full advantage of the brokerage’s local, national, and international resources. James Weekley puts technology to good use with stunning video tours of the Realtor’s most sought after listings, including waterfront properties along the Malibu coastline.',
  },
  {
    title: 'Tracy \nTutor',
    subtitle: 'Beverly Hills, CA',
    description:
      'Tracy Tutor is Douglas Elliman Beverly Hills’ top agent, representing iconic architects like Frank Gehry, Pierre Koenig, and John Lautner. At TracyTutor.com, Agent Image keeps it simple with an understated black and white palette. A photo of the Tracy Tutor Team greets site visitors at the homepage, putting a human face to this in-demand real estate brand.',
  },
  {
    title: 'Sally Forster Jones',
    subtitle: 'Beverly Hills, CA',
    description:
      'No one understands the need of luxury clients like Sally Forster, whose insight and professionalism has made for one of the most impressive careers in Southern California real estate. At SallyForsterJones.com, Agent Image captures her seemingly effortless work style with a restrained white, gold, and black color scheme that places the emphasis on breathtaking photos of the SoCal properties she represents.',
  },
  {
    title: 'Wanda Charles \nGroup',
    subtitle: 'Frisco, TX',
    description:
      'The Wanda Charles Group invites you to the ultimate real estate experience in Frisco Real Estate and nearby cities with their first rate service. Agent Image created a dramatic layout for WandaCharlesGroup.com with white text against a black background and red accents. The design pays homage to Lone Star State’s rough around the edges character with photos of weather worn elevated steel water tanks to represent each of the team’s service areas.',
  },
  {
    title: 'Alexander \nAnu',
    subtitle: 'Los Angeles, CA',
    description:
      'Insightful Realtors like Alexander Anu know that when it comes to the high end of the real estate market, clients want more than over the top features – they want a coveted lifestyle. Agent Image communicates this through the design for AnuLifestyle.com, which contains saturated images of sports cars, paintings, glistening pools, and all the perks of luxury living in sunny SoCal. The design is a bit more understated elsewhere, such as the austere grayscale image of Anu himself in the homepage.',
  },
  {
    title: 'Sarah \nBoyd',
    subtitle: 'Valley View, TX',
    description:
      'A jack of all trades, Sarah Boyd is an agent, entrepreneur, equestrian expert, and an active real estate developer and investor. SarahBoydRealty.com exemplifies luxury, featuring a parade of upscale homes in the image header and high quality videos of the brokerage’s finest listings. Agent Image knows that less is more, and so the website sticks with a simple but sophisticated color palette of black, white, and neutrals.',
  },
  {
    title: 'Nartey | Wilner',
    subtitle: 'Las Vegas, NV',
    description:
      'The Nartey Wilner Group caters to the crème of the crop, specializing in luxury real estate services for athletes and entertainers living the high roller lifestyle. That’s why the design for NarteyWilner.com has a flair for the dramatic, featuring a saturated palette reminiscent of Sin City’s neon lights, a textured background that evoke VIP-only nightclubs, and a gold accent fitting for a star-studded clientele.',
  },
  {
    title: 'Robert \nSlack',
    subtitle: 'Orlando, FL',
    description:
      'Robert Slack understands the demand of clients who live fast-paced lives. Known for their service and professionalism, the brokerage blends tradition with technology. RobertSlack.com features sun-drenched photos of Mediterranean-style homes, palm tree-lined boulevards, and Orlando’s iron-clad skyline in the image header. The red color accent works well against a white backdrop and exudes the warmth and passion of Sunshine State.',
  },
]
